const WhyUs = () => {
  return (
    <section id="why-us" className="wow fadeIn">
      <div className="container">
        <header className="section-header">
          <h3>Why choose us?</h3>
          <p>
            With our collaborative approach, cutting-edge solutions, and commitment to excellence, we're your trusted partner in achieving digital greatness.
          </p>
        </header>

        <div className="row row-eq-height justify-content-center">
          <div className="col-lg-4 mb-4">
            <div className="card wow bounceInUp">
              <i className="fa fa-diamond"></i>
              <div className="card-body">
                <h5 className="card-title">Expertise</h5>
                <p className="card-text">
                  Benefit from our seasoned professionals' deep knowledge and experience in the industry, ensuring top-tier solutions tailored to your needs.
                </p>
                <a href="#" className="readmore">
                  Read more{" "}
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div className="card wow bounceInUp">
              <i className="fa fa-language"></i>
              <div className="card-body">
                <h5 className="card-title">Support</h5>
                <p className="card-text">
                  Experience unparalleled dedication and support from our team, ensuring a smooth and successful journey from concept to implementation.
                </p>
                <a href="#" className="readmore">
                  Read more{" "}
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div className="card wow bounceInUp">
              <i className="fa fa-object-group"></i>
              <div className="card-body">
                <h5 className="card-title">Innovation</h5>
                <p className="card-text">
                  Stay ahead of the curve with our commitment to pioneering technologies and creative approaches, driving your business towards future success.
                </p>
                <a href="#" className="readmore">
                  Read more{" "}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row counters">
          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">274</span>
            <p>Clients</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">421</span>
            <p>Projects</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">1,364</span>
            <p>Hours Of Support</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">18</span>
            <p>Hard Workers</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
