const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 footer-info">
              <h4>Asthetic Innovation</h4>
              <p>
                At Asthetic Innovation, we pride ourselves on being your premier destination for cutting-edge IT services, specializing in software development and website building.
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                Tumacho 09 <br />
                Bagmati, Bhaktapur
                <br />
                Nepal <br />
                <strong>Phone:</strong> +977 9860170979
                <br />
                <strong>Email:</strong> info@astheticinnovation.com
                <br />
              </p>

              <div className="social-links">
                <a href="#" className="twitter">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#" className="facebook">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#" className="instagram">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="#" className="google-plus">
                  <i className="fa fa-google-plus"></i>
                </a>
                <a href="#" className="linkedin">
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
              Stay ahead with exclusive updates, industry insights, and expert tips. Our newsletter delivers the latest trends in technology, software development, and website building straight to your inbox. Join our community of innovators and elevate your business with Asthetic Innovation.
              </p>
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong>Asthetic Innovation</strong>. All Rights Reserved
        </div>
        {/* <div className="credits">
          Designed by <a href="#"></a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
