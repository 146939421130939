const Nav = () => {
  return (
    <nav className="main-nav float-right d-none d-lg-block">
      <ul>
        <li>
          <a href="#about">About Us</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li className="li-contact-us">
          <button className="btn-contact-us"><a href="#contact">Contact Us</a></button>
          <a className="mobile-responsive-contact-us" href="#contact">Contact Us</a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
