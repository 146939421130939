import { useCallback, useEffect } from "react";
import Home from "./pages/Home";

const scriptsSrc = [
  "/lib/bootstrap/js/bootstrap.bundle.min.js",
  "/lib/easing/easing.min.js",
  "/lib/mobile-nav/mobile-nav.js",
  "/lib/wow/wow.min.js",
  "/lib/waypoints/waypoints.min.js",
  "/lib/counterup/counterup.min.js",
  "/lib/owlcarousel/owl.carousel.min.js",
  "/lib/isotope/isotope.pkgd.min.js",
  "/lib/lightbox/js/lightbox.min.js",
  "/js/main.js",
];

function App() {
  const loadScripts = useCallback(async (head) => {
    if (scriptsSrc.length) {
      const script = document.createElement("script");
      script.onload = () => {
        loadScripts(head);
      };
      script.setAttribute("src", scriptsSrc.shift());
      head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const head = document.querySelector("head");
    loadScripts(head);
  }, [loadScripts]);

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
