const ContactUs = () => {
  return (
    <section id="contact">
      <div className="container-fluid">
        <div className="section-header">
          <h3>Contact Us</h3>
        </div>

        <div className="row wow fadeInUp">
          <div className="col-lg-6">
            <div className="map mb-4 mb-lg-0">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14927.406707609303!2d85.4261038038065!3d27.672435695583587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1aae42806ba1%3A0x5449e079404e5e82!2sBhaktapur%2C%20Nepal!5e0!3m2!1sen!2sus!4v1689742228744!5m2!1sen!2sus"
                style={{ border: 0, width: "100%", height: "312px" }}
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-5 info">
                <i className="ion-ios-location-outline"></i>
                <p>Tumacho 09, Bhaktapur Nepal</p>
              </div>
              <div className="col-md-4 info">
                <i className="ion-ios-email-outline"></i>
                <p>info@astheticinnovation.com</p>
              </div>
              <div className="col-md-3 info">
                <i className="ion-ios-telephone-outline"></i>
                <p>+977 9860170979</p>
              </div>
            </div>

            <div className="form">
              <div id="sendmessage">Your message has been sent. Thank you!</div>
              <div id="errormessage"></div>
              <form action="" method="post" role="form" className="contactForm">
                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validation"></div>
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <div className="validation"></div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"
                  />
                  <div className="validation"></div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Message"
                  ></textarea>
                  <div className="validation"></div>
                </div>
                <div className="text-center">
                  <button type="submit" title="Send Message">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
