import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AboutUs from "./AboutUs";
import Clients from "./Clients";
import ContactUs from "./ContactUs";
import Portfolio from "./Portfolio";
import Services from "./Services";
import Testimonials from "./Testimonials";
import WhyUs from "./WhyUs";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />

      <main id="main">
        <AboutUs />
        <Services />
        <WhyUs />
        <Portfolio />
        {/*<Testimonials />*/}
        {/*<Clients />*/}
        <ContactUs />
      </main>

      <Footer />
    </>
  );
};

export default Home;
