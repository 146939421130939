import Nav from "./Nav";

const Header = () => {
  return (
    <header id="header" className="fixed-top">
      <div className="container">
        <div className="logo float-left">
          <a href="#intro" className="scrollto">
          {/* <h2 className="text-light"><a href="#header"><span>Asthetic Innovation</span></a></h2> */}
            <img src="img/logo.png" alt="" className="img-fluid logo" />
          </a>
        </div>

        <Nav />
      </div>
    </header>
  );
};

export default Header;
