const Services = () => {
  return (
    <section id="services" className="section-bg">
      <div className="container">
        <header className="section-header">
          <h3>Services</h3>
          <p>
            Empowering Your Vision
          </p>
        </header>

        <div className="row">
          <div
            className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
            data-wow-duration="1.4s"
          >
            <div className="box">
              <div className="icon">
                <i
                  className="ion-ios-analytics-outline"
                  style={{ color: "#ff689b" }}
                ></i>
              </div>
              <h4 className="title">
                <a href="">Web Development</a>
              </h4>
              <p className="description">
                Crafting Digital Experiences. Elevating Your Online Presence.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-5 wow bounceInUp"
            data-wow-duration="1.4s"
          >
            <div className="box">
              <div className="icon">
                <i
                  className="ion-ios-bookmarks-outline"
                  style={{ color: "#e9bf06" }}
                ></i>
              </div>
              <h4 className="title">
                <a href="">Mobile Application Development</a>
              </h4>
              <p className="description">
                Innovative Solutions, Seamless Experiences.
                Bringing Your Ideas to Life on Every Screen.
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
            data-wow-delay="0.1s"
            data-wow-duration="1.4s"
          >
            <div className="box">
              <div className="icon">
                <i
                  className="ion-ios-paper-outline"
                  style={{ color: "#3fcdc7" }}
                ></i>
              </div>
              <h4 className="title">
                <a href="">SEO</a>
              </h4>
              <p className="description">
                Boosting Visibility, Maximizing Reach.
                Optimizing Your Online Presence.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-5 wow bounceInUp"
            data-wow-delay="0.1s"
            data-wow-duration="1.4s"
          >
            <div className="box">
              <div className="icon">
                <i
                  className="ion-ios-speedometer-outline"
                  style={{ color: "#41cf2e" }}
                ></i>
              </div>
              <h4 className="title">
                <a href="">Digital Marketing</a>
              </h4>
              <p className="description">
                Strategic Campaigns, Tangible Results.
                Driving Your Brand Forward in the Digital World.
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
            data-wow-delay="0.2s"
            data-wow-duration="1.4s"
          >
            <div className="box">
              <div className="icon">
                <i
                  className="ion-ios-world-outline"
                  style={{ color: "#d6ff22" }}
                ></i>
              </div>
              <h4 className="title">
                <a href="">Software Development</a>
              </h4>
              <p className="description">
                Transforming Ideas into Powerful Solutions.
                Customized Software to Fit Your Unique Needs.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-5 wow bounceInUp"
            data-wow-delay="0.2s"
            data-wow-duration="1.4s"
          >
            <div className="box">
              <div className="icon">
                <i
                  className="ion-ios-clock-outline"
                  style={{ color: "#4680ff" }}
                ></i>
              </div>
              <h4 className="title">
                <a href="">UI/UX Design</a>
              </h4>
              <p className="description">
                Elevating User Experiences, Inspiring Engagement.
                Crafting Intuitive Designs Tailored to Your Brand.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
