const AboutUs = () => {
  return (
    <section id="about">
      <div className="container">
        <header className="section-header">
          <h3>About Us</h3>
          <p>
            We are architects of innovation, creators of efficiency, and pioneers of digital transformation.
          </p>
        </header>

        <div className="row about-container">
          <div className="col-lg-6 content order-lg-1 order-2">
            <p>
              At Asthetic Innovation, we understand that in today's fast-paced digital world, having the right technology solutions can make all the difference for your business. That's why we are committed to providing top-notch services tailored to meet your unique needs and propel your business to new heights.
            </p>

            <div className="icon-box wow fadeInUp">
              <div className="icon">
                <i className="fa fa-shopping-bag"></i>
              </div>
              <h4 className="title">
                <a href="">Skilled Professionals</a>
              </h4>
              <p className="description">
                Our team of skilled professionals boasts extensive experience and expertise in software development, ensuring that we deliver robust, scalable, and customized solutions that align perfectly with your objectives
              </p>
            </div>

            <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
              <div className="icon">
                <i className="fa fa-photo"></i>
              </div>
              <h4 className="title">
                <a href="">Excellence</a>
              </h4>
              <p className="description">
                Our commitment to innovation, quality, and customer satisfaction sets us apart in the digital landscape.
              </p>
            </div>

            <div className="icon-box wow fadeInUp" data-wow-delay="0.4s">
              <div className="icon">
                <i className="fa fa-bar-chart"></i>
              </div>
              <h4 className="title">
                <a href="">Unlock Exponential Growth</a>
              </h4>
              <p className="description">
                Our comprehensive suite of IT services empowers businesses to thrive in today's dynamic market. From innovative software solutions to captivating website designs, we fuel your growth journey with creativity, expertise, and unparalleled support.
              </p>
            </div>
          </div>

          <div className="col-lg-6 background order-lg-2 order-1 wow fadeInUp">
            <img src="img/about-img.svg" className="img-fluid" alt="" />
          </div>
        </div>

        <div className="row about-extra">
          <div className="col-lg-6 wow fadeInUp">
            <img src="img/about-extra-1.svg" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
            <h4>
              Nurturing creative concepts into tangible solutions.
            </h4>
            <p>
              We thrive on fostering innovative ideas and turning them into reality. Our team of seasoned experts is dedicated to providing you with the support and guidance needed to bring your vision to life. Whether you're a startup looking to disrupt the industry or an established business aiming to stay ahead of the curve, we're here to help. From brainstorming sessions to prototype development and beyond, we'll work closely with you to refine your concept and transform it into a tangible product or solution that exceeds expectations.
            </p>
            <p>
              At Asthetic Innovation, innovation knows no bounds. Our collaborative approach and extensive experience empower us to transform your visions into impactful solutions. Join us on a journey of creativity and exploration, where together, we'll unlock opportunities for transformative growth.
            </p>
          </div>
        </div>

        <div className="row about-extra">
          <div className="col-lg-6 wow fadeInUp order-1 order-lg-2">
            <img src="img/about-extra-2.svg" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
            <h4>
              On Time Delivery
            </h4>
            <p>

              At Asthetic Innovation, we pride ourselves on our unwavering commitment to punctuality. Timely delivery is not just a goal but a fundamental principle that drives our operations. From the moment we embark on a project, our dedicated team members work tirelessly to ensure that deadlines are not only met but exceeded. We understand that delays can have significant implications for your business, which is why we employ efficient project management strategies and rigorous quality control measures to guarantee on-time delivery, every time.
            </p>
            <p>
              Our track record speaks for itself, with countless satisfied clients benefiting from our reliable and prompt service. Whether you're launching a new software application or revamping your website, you can rest assured that your project will be completed on schedule with Asthetic Innovation. Trust us to deliver results that not only meet your expectations but also align perfectly with your timeline and objectives.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
